import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BlogPostLink } from 'components/blog-post-link';
import { Container } from 'components/container';
import { HeaderFirst } from 'components/header-first';
import { SEO } from 'components/seo';
import { CONSTANTS } from 'constants/styles/constants';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/press-release.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';

const SArticles = styled.div`
    width: 100%;
    gap: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 5rem;

    ${CONSTANTS.MEDIA.max600`
           grid-template-columns: 1fr;
    `}
`;

const SHeaderFirst = styled(HeaderFirst)`
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 6rem;
`;

const SMainSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 9.75rem;
    background-color: var(--gray-color-90);

    ${CONSTANTS.MEDIA.max1024`
        padding-top: 5rem;
    `}
`;

const PressRelease = ({ location, data }) => {
    const featuredBlogPost = data.allContentfulBlogPost.edges[0]?.node;
    const blogPosts = data.allContentfulBlogPost.edges?.slice(1);

    return (
        <Layout location={location} messages={messages}>
            <SMainSection>
                {featuredBlogPost ? (
                    <Container>
                        <SHeaderFirst topParagraph="articles">
                            <FormattedMessage id="pressRelease" />
                        </SHeaderFirst>
                        <BlogPostLink
                            blogPost={featuredBlogPost}
                            hub="press-release"
                            isHorizontal
                            isPressRelease
                        />
                    </Container>
                ) : (
                    <SHeaderFirst topParagraph="pressRelease">
                        <FormattedMessage id="noPressReleasePublished" />
                    </SHeaderFirst>
                )}
            </SMainSection>
            {blogPosts.length > 0 && (
                <Container>
                    <SArticles>
                        {blogPosts.map(({ node: blogPost }) => (
                            <BlogPostLink
                                key={blogPost.id}
                                blogPost={blogPost}
                                hub="press-release"
                                isPressRelease
                            />
                        ))}
                    </SArticles>
                </Container>
            )}
        </Layout>
    );
};

export default PressRelease;

export const Head = () => <SEO tags={TAGS.PRESS_RELEASE} />;

export const pageQuery = graphql`
    query EnBlogPostQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: { eq: "en-US" }
                metadata: {
                    tags: {
                        elemMatch: {
                            contentful_id: { eq: "pressRelease" }
                            name: { ne: "nft-reality" }
                        }
                    }
                }
            }
            sort: { date: DESC }
        ) {
            edges {
                node {
                    id
                    title
                    genre
                    genreExpertise
                    genreTechnology
                    shortDescription {
                        internal {
                            content
                        }
                    }
                    date
                    createdAt
                    updatedAt
                    image {
                        gatsbyImageData(width: 772, height: 541, quality: 100)
                    }
                    thumbnail {
                        gatsbyImageData(width: 600, height: 420, quality: 100)
                    }
                    slug
                    altThumbnailDescription
                    multipleAuthors {
                        name
                        jobTitle
                        picture {
                            gatsbyImageData(width: 200, quality: 100)
                        }
                    }
                }
            }
        }
    }
`;
